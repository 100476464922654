/* eslint-disable max-classes-per-file  */

import {
  fileUploadPatch,
  fileUploadPost,
  getAwait,
  postAwait,
} from 'apis/methods';
import {
  EXTERNAL_APPLICANT_FIELDS,
  REVIEWS_PAGE_LIMIT,
} from 'helpers/constants';
import { addQueryParamToUrl } from 'services';
import store from 'store';

export default class APISDK {
  static defaultUserType = 'user';

  static CONFIG = {
    API_URL: process.env.REACT_APP_API_URL,
    FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  };

  static getFile = (url, headers = {}) =>
    new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers,
      })
        .then((res) => res.blob())
        .then((blob) => {
          resolve(blob);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });

  static getFileWithAuth(url) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${this.getAuthToken()}`);
    return this.getFile(url, headers);
  }

  static getUserType() {
    return store.get('userType') || this.defaultUserType;
  }

  static fileUpload = (url, filesArray, headers = {}, fileType = 'image') => {
    const formData = new FormEntry();

    for (let i = 0; i < filesArray.length; i += 1) {
      formData.append(fileType, filesArray[i], filesArray[i].name);
    }
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'PUT',
        body: formData,
        headers,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.result) {
            resolve(result.result);
          }
          if (result.error) {
            reject(result.error);
          }
        })
        .catch(reject);
    });
  };

  static fileUploadWithAuth(url, filesArray, fileType) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${this.getAuthToken()}`);
    return this.fileUpload(url, filesArray, headers, fileType);
  }

  static fileUploadPostWithAuth(url, filesArray, onUploadProgress) {
    return fileUploadPost(
      url,
      filesArray,
      { Authorization: `Bearer ${this.getAuthToken()}` },
      onUploadProgress
    );
  }

  static getAuthToken() {
    return store.get('token');
  }

  static put(
    url,
    payload,
    headers = {
      'Content-Type': 'application/json',
    }
  ) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'PUT',
        headers,
        body: JSON.stringify({
          payload,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.result) {
            resolve(result.result);
          }
          if (result.error) {
            reject(result.error);
          }
        })
        .catch(reject);
    });
  }

  static post(
    url,
    payload,
    headers = {
      'Content-Type': 'application/json',
    }
  ) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          payload,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.result) {
            resolve(result.result);
          }
          if (result.error) {
            reject(result.error);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }

  static postAwait = async (
    url,
    payload,
    headers = {
      'Content-Type': 'application/json',
    }
  ) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          payload,
        }),
      });
      const res = await response.json();
      return res;
    } catch (error) {
      return error;
    }
  };

  static postWithAuth(url, payload) {
    const authToken = this.getAuthToken();
    return this.post(url, payload, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    });
  }

  static postAwaitWithAuth(url, payload) {
    const authToken = this.getAuthToken();
    return postAwait(url, payload, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    });
  }

  static putAwait = async (
    url,
    payload,
    headers = {
      'Content-Type': 'application/json',
    }
  ) => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers,
        body: JSON.stringify({
          payload,
        }),
      });
      const res = await response.json();
      return res;
    } catch (error) {
      return error;
    }
  };

  static putAwaitWithAuth(url, payload) {
    const authToken = this.getAuthToken();
    return this.putAwait(url, payload, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    });
  }

  static putWithAuth(url, payload) {
    const authToken = this.getAuthToken();
    return this.put(url, payload, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    });
  }

  static patch(
    url,
    payload,
    headers = {
      'Content-Type': 'application/json',
    }
  ) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'PATCH',
        headers,
        body: JSON.stringify({
          payload,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.result) {
            resolve(result.result);
          }
          if (result.error) {
            reject(result.error);
          }
        })
        .catch(reject);
    });
  }

  static patchAwait = async (
    url,
    payload,
    headers = {
      'Content-Type': 'application/json',
    }
  ) => {
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers,
        body: JSON.stringify({
          payload,
        }),
      });
      const res = await response.json();
      return res;
    } catch (error) {
      return error;
    }
  };

  static patchAwaitWithAuth(url, payload) {
    const authToken = this.getAuthToken();
    return this.patchAwait(url, payload, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    });
  }

  static patchWithAuth(url, payload) {
    const authToken = this.getAuthToken();
    return this.patch(url, payload, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    });
  }

  static get(url, headers = {}) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers,
      })
        .then((res) => res.json())
        .then((result) => {
          resolve(result.result);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }

  static getAwait = async (url, headers = {}) => {
    const response = await fetch(url, {
      method: 'GET',
      headers,
    });
    const res = await response.json();
    return res;
  };

  static getWithAuth(url) {
    return this.get(url, { Authorization: `Bearer ${this.getAuthToken()}` });
  }

  static getAwaitWithAuth(url) {
    return getAwait(url, { Authorization: `Bearer ${this.getAuthToken()}` });
  }

  static Delete = (url, payload, headers = {}) =>
    new Promise((resolve, reject) => {
      fetch(url, {
        method: 'DELETE',
        headers,
        body: JSON.stringify({
          payload,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.result) {
            resolve(result.result);
          }
          if (result.error) {
            reject(result.error);
          }
        })
        .catch(reject);
    });

  static DeleteWithAuth(url, payload = {}) {
    return this.Delete(url, payload, {
      Authorization: `Bearer ${this.getAuthToken()}`,
    });
  }

  static deleteAwait = async (
    url,
    payload,
    headers = {
      'Content-Type': 'application/json',
    }
  ) => {
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers,
        body: JSON.stringify({
          payload,
        }),
      });
      const res = await response.json();
      return res;
    } catch (error) {
      return error;
    }
  };

  static deleteAwaitWithAuth(url, payload) {
    return this.deleteAwait(url, payload, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getAuthToken()}`,
    });
  }
}
export class Auth extends APISDK {
  static url = `${this.CONFIG.API_URL}/auth`;

  static verifyOTP = async (payload) =>
    this.postAwait(`${this.url}/verify-otp`, payload);

  static login = async (payload) => {
    return this.postAwait(`${this.url}/login`, payload);
  };

  static logout = async (payload) =>
    this.postAwait(`${this.url}/logout`, payload);

  static signUp = async (payload) => {
    return this.postAwait(`${this.url}/signup`, payload);
  };

  static signupByInvite = async (payload) => {
    return this.postAwait(`${this.url}/invite-signup`, payload);
  };

  static signUpCreate = async (payload) => {
    return this.putAwait(`${this.url}/signup`, payload);
  };

  static getSignupDetails = async (tokenId) => {
    return this.getAwait(`${this.url}/signup?tokenId=${tokenId}`);
  };

  static verifyEmail = async (payload) => {
    return this.patchAwait(`${this.url}/verify-email`, payload);
  };

  static forgotPassword = async (payload) =>
    this.postAwait(`${this.url}/forgot-password`, payload);

  static resetPassword = async (payload) =>
    this.postAwait(`${this.url}/reset-password`, payload);

  static changePassword = async (payload) =>
    this.postAwaitWithAuth(`${this.url}/change-password`, {
      payload,
    });

  static resendVerificationEmail = async (tokenId) =>
    this.getAwait(`${this.url}/resend-email?tokenId=${tokenId}`);

  static createOrganization = (payload) =>
    this.postAwaitWithAuth(`${this.url}/organisation-signup`, { payload });
}

export class User extends APISDK {
  static url = `${this.CONFIG.API_URL}`;

  static loadUserAccount = async () => this.getAwaitWithAuth(`${this.url}/me`);

  static updateUserAccount = async (payload) => {
    const url = `${this.url}/me`;
    if (payload.fileUpload) {
      return fileUploadPatch(url, payload.data, {
        Authorization: `Bearer ${this.getAuthToken()}`,
      });
    }
    return this.patchAwaitWithAuth(url, payload.data);
  };

  static addToCart = async (payload) =>
    this.putAwaitWithAuth(`${this.url}/cart`, payload);

  static createUser = (payload) =>
    this.postAwaitWithAuth(`${this.url}/users`, payload.data);

  static listUsers = (payload) => {
    let apiUrl = `${this.url}/users`;
    if (payload?.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload.searchTerm);
    }
    return this.getAwaitWithAuth(apiUrl);
  };

  static getUser = (payload) =>
    this.getAwaitWithAuth(`${this.url}/users/${payload.id}`);

  static updateUser = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/users/${payload.id}`, payload.data);

  static deleteUser = (payload) =>
    this.DeleteWithAuth(`${this.url}/users/${payload.id}`);

  static getMyOrganization = () =>
    this.getAwaitWithAuth(`${this.url}/organisations/my`);

  static createOrganizationDomain = (payload) =>
    this.postAwaitWithAuth(`${this.url}/organisations/domain`, payload);

  static updateOrganizationSlug = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/organisations/slug`, payload);

  static getOrganizationDomains = () =>
    this.getAwaitWithAuth(`${this.url}/organisations/domain`);

  static updateOrganization = async (payload) => {
    if (payload?.fileUpload) {
      return fileUploadPatch(`${this.url}/organisations/my`, payload.data, {
        Authorization: `Bearer ${this.getAuthToken()}`,
      });
    }
    return this.patchAwaitWithAuth(
      `${this.url}/organisations/my`,
      payload.data
    );
  };

  static addDummyDataToOrg = () =>
    this.postAwaitWithAuth(`${this.url}/organisations/dummy-data`);

  static deleteDummyDataFromOrg = () =>
    this.deleteAwaitWithAuth(`${this.url}/organisations/dummy-data`);
}

export class Archived extends APISDK {
  static url = `${this.CONFIG.API_URL}/archiveds`;

  static listArchived = () => this.getAwaitWithAuth(`${this.url}`);

  static createArchived = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, payload.data);

  static getArchived = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.memberId}`);

  static updateArchived = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.memberId}`, payload.data);

  static deleteArchived = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.memberId}`);
}

export class Event extends APISDK {
  static url = `${this.CONFIG.API_URL}/events`;

  static createEvent = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listEvent = () => {
    return this.getAwaitWithAuth(`${this.url}`);
  };

  static getEvent = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateEvent = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteEvent = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.id}`);
}

export class Screen extends APISDK {
  static url = `${this.CONFIG.API_URL}/screens`;

  static createScreen = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listScreen = () => this.getAwaitWithAuth(`${this.url}`);

  static getScreen = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateScreen = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteScreen = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.id}`);
}

export class Job extends APISDK {
  static url = `${this.CONFIG.API_URL}/jobs`;

  static createJob = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listJob = ({ payload }) => {
    let apiUrl = `${this.url}?sortBy=createdAt:desc`;
    if (payload.status) {
      apiUrl = addQueryParamToUrl(apiUrl, 'status', payload.status);
    }
    if (payload.page) apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    if (payload.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    }
    return this.getAwaitWithAuth(apiUrl);
  };

  static getJob = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateJob = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteJob = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.id}`);

  static duplicateJob = (payload) =>
    this.postAwaitWithAuth(`${this.url}/${payload.id}/duplicate`);

  static addMembertoHiringTeam = (payload) =>
    this.postAwaitWithAuth(`${this.url}/${payload.id}/hiring-team`, {
      payload: payload.data,
    });

  static deleteMemberToHiringTeam = (payload) => {
    let apiUrl = `${this.url}/${payload.id}/hiring-team`;
    if (payload?.user) {
      apiUrl = addQueryParamToUrl(apiUrl, 'user', payload?.user);
    }
    return this.deleteAwaitWithAuth(apiUrl);
  };
}

export class Applicant extends APISDK {
  static url = `${this.CONFIG.API_URL}/candidates`;

  static createApplicant = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listApplicant = (payload) => {
    let apiUrl = `${this.url}/fetch?sortBy=createdAt:desc`;
    const payloadBody = { formData: {} };

    if (payload?.filter) {
      const notNullFilter = {};
      Object.keys(payload.filter).forEach((key) => {
        if (payload.filter[key]) {
          const filterValue = payload.filter[key];
          if (Object.keys(EXTERNAL_APPLICANT_FIELDS).includes(key)) {
            payloadBody[key] = filterValue;
          } else notNullFilter[key] = filterValue;
        }
      });
      if (Object.keys(notNullFilter).length > 0) {
        payloadBody.formData = notNullFilter;
      }
    }
    if (payload.searchTerm)
      apiUrl = addQueryParamToUrl(
        apiUrl,
        'searchTerm',
        encodeURIComponent(payload.searchTerm)
      );

    if (payload.jobId)
      apiUrl = addQueryParamToUrl(apiUrl, 'job', payload.jobId);

    if (payload.currentStage)
      apiUrl = addQueryParamToUrl(apiUrl, 'stage', payload.currentStage);

    if (payload.page) apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);

    if (payload.limit)
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);

    if (payload.user) apiUrl = addQueryParamToUrl(apiUrl, 'user', payload.user);

    return this.postAwaitWithAuth(apiUrl, { payload: payloadBody });
  };

  static getApplicant = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateApplicant = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.applicantId}`, payload.data);

  static updateBulkApplicant = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/bulk`, payload.data);

  static deleteApplicant = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);

  static exportApplicant = () => {
    this.postAwaitWithAuth(`${this.url}/export`);
  };

  static importApplicants = (jobId, payload, stage = null) => {
    let url = `${this.url}/import/${jobId}`;
    if (stage) url += `?stage=${stage}`;
    return this.fileUploadPostWithAuth(url, payload);
  };

  static getApplicantActivity = (payload) => {
    let apiUrl = `${this.CONFIG.API_URL}/user-activity`;
    if (payload.id)
      apiUrl = addQueryParamToUrl(apiUrl, 'candidate', payload.id);
    if (payload.page) apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    if (payload.limit)
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);

    apiUrl = addQueryParamToUrl(apiUrl, 'sortBy', 'updatedAt:desc');

    return this.getAwaitWithAuth(`${apiUrl}`);
  };

  static listAllApplicant = (payload) => {
    let apiUrl = `${this.url}?sortBy=createdAt:desc`;
    if (payload.page) apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    if (payload.limit)
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    return this.getAwaitWithAuth(apiUrl);
  };
}

export class Tag extends APISDK {
  static url = `${this.CONFIG.API_URL}/tag`;

  static createTag = (payload) =>
    this.postAwaitWithAuth(this.url, {
      payload: payload.data,
    });

  static listTags = (payload) => {
    let apiUrl = `${this.url}`;
    if (payload.searchTerm)
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload.searchTerm);
    return this.getAwaitWithAuth(apiUrl);
  };

  static deleteTag = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);
}

export class JobApplicationForm extends APISDK {
  static url = `${this.CONFIG.API_URL}/jobs`;

  static getJobApplicationForm = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}/form`);
}

export class Form extends APISDK {
  static url = `${this.CONFIG.API_URL}/forms`;

  static createForm = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload });

  static getForm = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static deleteForm = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);

  static listForm = (payload) => {
    let apiUrl = `${this.url}?sortBy=createdAt:desc`;

    if (payload?.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload?.searchTerm);
    }

    if (payload?.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload?.page);
    }

    if (payload?.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload?.limit);
    }

    if (payload?.type) {
      apiUrl = addQueryParamToUrl(apiUrl, 'type', payload?.type);
    }

    return this.getAwaitWithAuth(apiUrl);
  };

  static updateForm = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);
}

export class Interview extends APISDK {
  static url = `${this.CONFIG.API_URL}/interview`;

  static createInterview = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload });

  static listInterview = (payload) => {
    let apiUrl = `${this.url}?sortBy=updatedAt:desc`;
    if (payload.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    }
    if (payload.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    }
    if (payload.id) {
      apiUrl = addQueryParamToUrl(apiUrl, 'candidate', payload.id);
    }
    return this.getAwaitWithAuth(apiUrl);
  };

  static getInterview = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateInterview = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteInterview = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);

  static syncCalBookings = () => this.getAwaitWithAuth(`${this.url}/sync`);
}

export class Decide extends APISDK {
  static url = `${this.CONFIG.API_URL}/decides`;

  static createDecide = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listDecide = () => this.getAwaitWithAuth(`${this.url}`);

  static getDecide = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateDecide = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteDecide = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.id}`);
}

export class Offer extends APISDK {
  static url = `${this.CONFIG.API_URL}/offers`;

  static createOffer = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listOffer = () => this.getAwaitWithAuth(`${this.url}`);

  static getOffer = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateOffer = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteOffer = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.id}`);
}

export class Hired extends APISDK {
  static url = `${this.CONFIG.API_URL}/hireds`;

  static listHired = () => this.getAwaitWithAuth(`${this.url}`);

  static createHired = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, payload.data);

  static getHired = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.memberId}`);

  static updateHired = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.memberId}`, payload.data);

  static deleteHired = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.memberId}`);
}

export class Candidate extends APISDK {
  static url = `${this.CONFIG.API_URL}/candidates`;

  static listCandidates = ({ searchTerm, page = 1 }) =>
    this.getAwaitWithAuth(`${this.url}?searchTerm=${searchTerm}&page=${page}`);
}

export class Order extends APISDK {
  static url = `${this.CONFIG.API_URL}/orders`;

  static createOrder = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listOrder = () => this.getAwaitWithAuth(`${this.url}`);

  static getOrder = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateOrder = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteOrder = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.id}`);
}

export class FormEntry extends APISDK {
  static url = `${this.CONFIG.API_URL}/form-entry`;

  static getFormEntries = (payload) => {
    let apiUrl = `${this.url}`;
    if (payload.candidate) {
      apiUrl = addQueryParamToUrl(apiUrl, 'candidate', payload.candidate);
    }
    if (payload.stage) {
      apiUrl = addQueryParamToUrl(apiUrl, 'stage', payload.stage);
    }
    if (payload.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    }
    if (payload.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    }
    return this.getAwaitWithAuth(apiUrl);
  };

  static getFormEntry = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.formEntryId}`);

  static updateFormEntry = (payload) =>
    this.putAwaitWithAuth(
      `${this.url}/${payload.formEntryId}`,
      payload.formEntryData
    );

  static deleteFormEntry = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.formEntryId}`);

  static updateApplicantFormEntry = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.formEntryId}`, payload.data);
}

export class Pages extends APISDK {
  static url = `${this.CONFIG.API_URL}/page`;

  static getPages = (payload = {}) => {
    let apiUrl = `${this.url}?sortBy=created:desc`;
    if (payload.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload.searchTerm);
    }
    if (payload.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    }
    if (payload.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    }
    return this.getAwaitWithAuth(apiUrl);
  };

  static getPage = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updatePage = (payload = {}) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deletePage = (payload) =>
    this.DeleteWithAuth(`${this.url}/${payload.id}`);

  static createPage = (payload) =>
    this.postAwaitWithAuth(this.url, { payload });
}

export class Media extends APISDK {
  static url = `${process.env.REACT_APP_API_URL}/media`;

  static uploadMedia = async (payload) =>
    this.fileUploadPostWithAuth(`${this.url}`, payload);

  static uploadMediaWithAuth = async (payload) =>
    this.fileUploadPostWithAuth(`${this.url}/my`, payload);

  static uploadResumeMedia = async (payload) =>
    this.fileUploadPostWithAuth(`${this.url}/resume`, payload);

  static publicUploadMedia = async (payload) =>
    fileUploadPost(`${this.url}`, payload);

  static getMedias = (payload = {}) => {
    let apiUrl = `${this.url}?sortBy=updatedAt:desc`;
    if (payload.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    }
    if (payload.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    }
    if (payload.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload.searchTerm);
    }
    return this.getAwaitWithAuth(apiUrl);
  };
}

export class Messages extends APISDK {
  static url = `${this.CONFIG.API_URL}/message`;

  static listMessages = (payload) => {
    let apiUrl = `${this.url}?sortBy=updatedAt:desc`;
    if (payload.job) {
      apiUrl = addQueryParamToUrl(apiUrl, 'job', payload.job);
    }
    if (payload.to) {
      apiUrl = addQueryParamToUrl(apiUrl, 'to', payload.to);
    }
    if (payload.stage) {
      apiUrl = addQueryParamToUrl(apiUrl, 'stage', payload.stage);
    }
    if (payload.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    }
    if (payload.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    }
    if (payload.type) {
      apiUrl = addQueryParamToUrl(apiUrl, 'type', payload.type);
    }
    return this.getAwaitWithAuth(apiUrl);
  };

  static createMessage = (payload, params) => {
    let apiUrl = `${this.url}`;
    if (params?.replyTo) {
      apiUrl = addQueryParamToUrl(apiUrl, 'replyTo', params.replyTo);
    }

    return this.postAwaitWithAuth(apiUrl, payload);
  };

  static sendBulkMessage = (payload) =>
    this.postAwaitWithAuth(`${this.url}/bulk`, payload);

  static listAllMessages = (payload) => {
    let apiUrl = `${this.url}?sortBy=updatedAt:desc`;
    if (payload.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    }
    if (payload.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    }
    if (payload.type) {
      apiUrl = addQueryParamToUrl(apiUrl, 'type', payload.type);
    }
    if (payload.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload.searchTerm);
    }
    return this.getAwaitWithAuth(apiUrl);
  };

  static getMessage = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static getUnreadCount = () => this.getAwaitWithAuth(`${this.url}/count`);
}

export class Comment extends APISDK {
  static url = `${this.CONFIG.API_URL}/comment`;

  static createComment = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listComment = (payload) => {
    let apiUrl = `${this.url}`;
    if (payload.id) {
      apiUrl = addQueryParamToUrl(apiUrl, 'candidate', payload.id);
    }
    apiUrl = addQueryParamToUrl(apiUrl, 'sortBy', 'updatedAt:desc');

    return this.getAwaitWithAuth(apiUrl);
  };

  static getComment = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateComment = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteComment = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);
}

export class Review extends APISDK {
  static url = `${this.CONFIG.API_URL}/review`;

  static createReview = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listReview = (payload) => {
    let apiUrl = `${this.url}`;
    if (payload.id) {
      apiUrl = addQueryParamToUrl(apiUrl, 'candidate', payload.id);
    }
    if (payload.page) apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    apiUrl = addQueryParamToUrl(apiUrl, 'limit', REVIEWS_PAGE_LIMIT);
    return this.getAwaitWithAuth(apiUrl);
  };

  static getReview = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateReview = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteReview = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);
}

export class Portal extends APISDK {
  static url = `${this.CONFIG.API_URL}/public/organisations`;

  static listOrgJobs = (payload) => {
    let apiUrl = `${this.url}/${payload.slug}/jobs`;
    if (payload.page) apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    if (payload.limit)
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);

    return this.getAwait(apiUrl);
  };

  static getOrgJobDetails = (payload) =>
    this.getAwait(
      `${this.url}/${payload.slug}/jobs/${payload.jobSlug}/details`
    );

  static getOrganizationDetails = (payload) =>
    this.getAwait(`${this.url}/${payload.slug}`);

  static getApplicationFormDetails = (payload) =>
    this.getAwait(`${this.url}/${payload.slug}/jobs/${payload.jobSlug}/form`);

  static applyPortalJob = (payload) =>
    this.postAwait(
      `${this.url}/${payload.slug}/jobs/${payload.jobSlug}/apply`,
      payload.data
    );
}

export class Teams extends APISDK {
  static url = `${this.CONFIG.API_URL}/team`;

  static listTeams = () => this.getAwaitWithAuth(`${this.url}`);

  static getTeam = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static deleteTeamMember = (payload) => {
    return this.deleteAwaitWithAuth(
      `${this.url}/${payload.teamId}/member/${payload.memberId}`
    );
  };

  static inviteTeamMember = (payload) => {
    return this.postAwaitWithAuth(`${this.url}/${payload.teamId}/invite`, {
      payload: payload.data,
    });
  };

  static getInviteDetails = (payload) => {
    return this.getAwait(`${this.url}/invite/${payload.invite}`);
  };

  static listInvites = (payload) => {
    let apiUrl = `${this.url}/invites`;

    if (payload?.team) {
      apiUrl = addQueryParamToUrl(apiUrl, 'team', payload.team);
    }
    if (payload.page) apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    if (payload.limit)
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);

    return this.getAwaitWithAuth(apiUrl);
  };

  static resendInvite = (payload) => {
    return this.patchAwaitWithAuth(`${this.url}/invite/${payload.id}`);
  };

  static deleteInvite = (payload) => {
    return this.deleteAwaitWithAuth(`${this.url}/invite/${payload.id}`);
  };

  static listTeamByKey = ({ teamId, page = 1, limit = 10, key = 'admins' }) => {
    const apiUrl = `${this.url}/${teamId}/${key}?page=${page}&limit=${limit}`;
    return this.getAwaitWithAuth(apiUrl);
  };
}

export class Template extends APISDK {
  static url = `${this.CONFIG.API_URL}/template`;

  static createTemplate = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static listTemplate = (payload) => {
    let apiUrl = `${this.url}`;
    if (payload.searchTerm)
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload.searchTerm);
    if (payload.page) apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    if (payload.limit)
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    if (payload.sortBy)
      apiUrl = addQueryParamToUrl(apiUrl, 'sortBy', payload.sortBy);

    return this.getAwaitWithAuth(apiUrl);
  };

  static getTemplate = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateTemplate = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteTemplate = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);
}

export class Integration extends APISDK {
  static url = `${this.CONFIG.API_URL}/integration`;

  static addCalIntegration = (payload) => {
    return this.postAwaitWithAuth(`${this.url}/cal`, {
      payload,
    });
  };

  static checkCalIntegration = () => {
    return this.getAwaitWithAuth(`${this.url}/cal`);
  };

  static getCalEvents = () => {
    return this.getAwaitWithAuth(`${this.url}/cal/events`);
  };

  static createCalEvent = (payload) => {
    return this.postAwaitWithAuth(`${this.url}/cal/events`, { payload });
  };

  static addCalWebhook = () => {
    return this.patchAwaitWithAuth(`${this.url}/cal/webhook`);
  };
}

export class Stage extends APISDK {
  static url = `${this.CONFIG.API_URL}/stage`;

  static listStages = (payload) => {
    let apiUrl = `${this.url}`;
    if (payload?.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload?.searchTerm);
    }
    return this.getAwaitWithAuth(apiUrl);
  };

  static getStageById = ({ payload }) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`, payload);

  static addStage = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload });

  static updateStage = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteStage = (payload) => {
    let apiUrl = `${this.url}/${payload.id}`;

    if (payload?.workflow) {
      apiUrl = addQueryParamToUrl(apiUrl, 'workflow', payload.workflow);
    }

    if (payload?.migrateToStage) {
      apiUrl = addQueryParamToUrl(
        apiUrl,
        'migrateToStage',
        payload.migrateToStage
      );
    }
    return this.deleteAwaitWithAuth(apiUrl);
  };
}

export class Workflow extends APISDK {
  static url = `${this.CONFIG.API_URL}/workflow`;

  static listWorkflows = (payload) => {
    let apiUrl = `${this.url}`;
    if (payload?.searchTerm)
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload.searchTerm);

    if (payload?.page)
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);

    if (payload?.limit)
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);

    return this.getAwaitWithAuth(apiUrl);
  };

  static getWorkflowById = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`, payload);

  static createWorkflow = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, payload);

  static updateWorkflow = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteWorkflow = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`, payload);
}

export class Rule extends APISDK {
  static url = `${this.CONFIG.API_URL}/rule`;

  static createRule = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static getRule = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateRule = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteRule = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);

  static listRules = (payload) => {
    let apiUrl = `${this.url}`;
    if (payload?.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload?.searchTerm);
    }
    if (payload?.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload?.page);
    }

    if (payload?.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload?.limit);
    }

    if (payload?.job) {
      apiUrl = addQueryParamToUrl(apiUrl, 'job', payload?.job);
    }
    return this.getAwaitWithAuth(apiUrl);
  };
}

export class AIAgent extends APISDK {
  static url = `${this.CONFIG.API_URL}/ai-agent`;

  static createAIAgent = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload });

  static getAIAgent = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateAIAgent = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteAIAgent = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);

  static listAIAgents = (payload) => {
    let apiUrl = `${this.url}?sortBy=createdAt:desc`;
    if (payload?.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload?.searchTerm);
    }
    if (payload?.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload?.page);
    }

    if (payload?.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload?.limit);
    }
    return this.getAwaitWithAuth(apiUrl);
  };
}

export class Category extends APISDK {
  static url = `${this.CONFIG.API_URL}/category`;

  static createCategory = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static getCategory = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateCategory = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteCategory = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);

  static listCategories = (payload) => {
    let apiUrl = `${this.url}`;

    if (payload?.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload?.searchTerm);
    }

    if (payload?.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload?.page);
    }

    if (payload?.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload?.limit);
    }
    return this.getAwaitWithAuth(apiUrl);
  };
}

export class Domain extends APISDK {
  static url = `${this.CONFIG.API_URL}/domain`;

  static createDomain = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload });

  static getDomains = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateDomain = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteDomain = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);

  static listDomains = () => this.getAwaitWithAuth(this.url);

  static checkEmailDomainStatus = () => this.getAwaitWithAuth(`${this.url}/my`);
}

export class TalentCommunity extends APISDK {
  static url = `${this.CONFIG.API_URL}/talent-community`;

  static createTalentApplicant = (payload) =>
    this.postAwaitWithAuth(`${this.url}`, { payload: payload.data });

  static getTalentApplicant = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateTalentApplicant = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteTalentApplicant = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);

  static listTalentCommunity = (payload) => {
    let apiUrl = `${this.url}`;

    if (payload?.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload?.searchTerm);
    }

    if (payload?.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload?.page);
    }

    if (payload?.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload?.limit);
    }
    return this.getAwaitWithAuth(apiUrl);
  };
}

export class Payment extends APISDK {
  static url = `${this.CONFIG.API_URL}/payment`;

  static getPlanDetails = () => this.getAwait(`${this.url}/plans`);

  static getSubscription = (payload) =>
    this.getAwaitWithAuth(`${this.url}?plan=${payload.plan}`);

  static verifyPayment = (payload) =>
    this.patchAwaitWithAuth(`${this.url}/verify`, payload);

  static cancelSubscription = () =>
    this.deleteAwaitWithAuth(`${this.url}/cancel`);
}

export class MagicLink extends APISDK {
  static url = `${this.CONFIG.API_URL}/auth/magic-link`;

  static createMatchLink = (payload) => this.postAwait(this.url, payload);

  static verifyMagicLink = (tokenId) =>
    this.getAwait(`${this.url}?tokenId=${tokenId}`);
}

export class CallRecording extends APISDK {
  static url = `${this.CONFIG.API_URL}/call`;

  static listRecordings = (payload) => {
    let apiUrl = `${this.url}`;
    if (payload.candidate) {
      apiUrl = addQueryParamToUrl(apiUrl, 'candidate', payload.candidate);
    }
    if (payload?.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    }
    if (payload?.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    }

    return this.getAwaitWithAuth(apiUrl);
  };

  static getRecordingById = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);
}

export class ArchiveReason extends APISDK {
  static url = `${this.CONFIG.API_URL}/archive-reason`;

  static getArchiveReasons = (payload = {}) => {
    let apiUrl = `${this.url}`;
    if (payload.searchTerm) {
      apiUrl = addQueryParamToUrl(apiUrl, 'searchTerm', payload.searchTerm);
    }
    if (payload.page) {
      apiUrl = addQueryParamToUrl(apiUrl, 'page', payload.page);
    }
    if (payload.limit) {
      apiUrl = addQueryParamToUrl(apiUrl, 'limit', payload.limit);
    }
    return this.getAwaitWithAuth(apiUrl);
  };

  static getArchiveReason = (payload) =>
    this.getAwaitWithAuth(`${this.url}/${payload.id}`);

  static updateArchiveReason = (payload = {}) =>
    this.patchAwaitWithAuth(`${this.url}/${payload.id}`, payload.data);

  static deleteArchiveReason = (payload) =>
    this.deleteAwaitWithAuth(`${this.url}/${payload.id}`);

  static createArchiveReason = (payload) =>
    this.postAwaitWithAuth(this.url, { payload });
}

export class AI extends APISDK {
  static url = `${this.CONFIG.API_URL}/ai`;

  static filterByQuery = (payload) => {
    const apiUrl = `${this.url}/query`;
    return this.putAwaitWithAuth(apiUrl, payload);
  };
}

export class Slack extends APISDK {
  static url = `${this.CONFIG.API_URL}/slack`;

  static getSlackInstallURL = () => {
    return this.getAwaitWithAuth(`${this.url}/auth`);
  };

  static syncSlack = () => {
    return this.patchAwaitWithAuth(`${this.url}/sync`);
  };
}
