import { message } from 'antd';
import { Applicant, Messages } from 'apis/APISDK';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { handleApiError } from 'redux/error';
import actions from './actions';

function* LIST_MESSAGES(payloads) {
  const { payload } = payloads;
  if (payload.page && payload.page === 1) {
    yield put({ type: actions.SET_STATE, payload: { messageLoading: true } });
  }
  try {
    if (payload.candidate && !payload.to) {
      const { applicantList, currentApplicant } = yield select(
        (state) => state.applicants
      );
      const sentToApplicant = applicantList.find(
        (c) => c.id === payload.candidate
      );
      if (sentToApplicant) payload.to = sentToApplicant.user;
      else payload.to = currentApplicant?.user;
    }
    const { data, success, status } = yield call(
      Messages.listMessages,
      payload
    );
    if (success) {
      if (payload.page > 1) {
        const { messageList } = yield select((state) => state.messages);
        yield put({
          type: actions.SET_STATE,
          payload: {
            messageList: [...messageList, ...data.results],
            messageLoading: false,
            messageCurrentPage: data.page,
          },
        });
      } else
        yield put({
          type: actions.SET_STATE,
          payload: {
            messageList: data.results,
            messageLoading: false,
            messageTotalPages: data.totalPages,
            messageCurrentPage: data.page,
          },
        });
    } else {
      yield handleApiError(status);
      yield put({
        type: actions.SET_STATE,
        payload: { messageLoading: false },
      });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { messageLoading: false } });
  }
}

function* CREATE_MESSAGE(payloads) {
  const { payload: reqPayload, params } = payloads;
  yield put({ type: actions.SET_STATE, payload: { messageSubmitting: true } });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(
      Messages.createMessage,
      {
        payload: { ...reqPayload },
      },
      params
    );

    if (success) {
      if (data) {
        const { userData } = yield select((state) => state.user);
        const createdMessage = {
          ...data,
          from: {
            id: data.from,
            name: userData?.name,
            email: data?.sentFrom,
          },
        };
        yield put({
          type: actions.UPDATE_LAST_CREATED_MESSAGE,
          payload: {
            data: createdMessage,
            params,
          },
        });
        yield put({
          type: actions.UPDATE_LAST_CREATED_ALL_MESSAGE,
          payload: {
            data: createdMessage,
            params,
          },
        });
      } else {
        yield put({
          type: actions.INVOKE_LIST_MESSAGES,
          payload: { job: reqPayload.job, candidate: payloads.applicantId },
        });
      }
      message.success(resMessage);
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { messageSubmitting: false },
    });
  }
}

function* SEND_BULK_MESSAGE(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { messageSubmitting: true } });
  try {
    const { success, message: resMessage } = yield call(
      Messages.sendBulkMessage,
      {
        payload,
      }
    );
    if (success) {
      message.success(resMessage);
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { messageSubmitting: false },
    });
  }
}

function* LIST_ALL_MESSAGES({ payload }) {
  if (!payload.type) {
    yield put({ type: actions.RESET_ALL_MESSAGES });
    return;
  }

  if (payload.page === 1) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        allMessageLoading: true,
        allMessageList: [],
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    });
  }
  try {
    const {
      data,
      success,
      status,
      message: resMessage,
    } = yield call(Messages.listAllMessages, payload);
    if (success) {
      if (data.page > 1) {
        yield put({
          type: actions.SET_ADD_ALL_MESSAGE_LIST,
          payload: {
            allMessageList: data.results,
            allMessageCurrentPage: data.page,
            allMessageTotalPages: data.totalPages,
            allMessagesTotalResults: data.totalResults,
          },
        });
      } else {
        yield put({
          type: actions.SET_STATE,
          payload: {
            allMessageList: data.results,
            allMessageTotalPages: data.totalPages,
            allMessagesTotalResults: data.totalResults,
            allMessageLimit: data.limit,
            allMessageCurrentPage: data.page,
          },
        });
      }
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { allMessageLoading: false },
    });
  }
}

function* GET_MESSAGE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const {
      success,
      data,
      status,
      message: resMessage,
    } = yield call(Messages.getMessage, payload);
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false, currentMessage: data },
      });
      yield put({
        type: actions.UPDATE_IN_MESSAGE_LIST,
        payload: { data },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* GET_ALL_MESSAGE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const {
      success,
      data,
      status,
      message: resMessage,
    } = yield call(Messages.getMessage, payload);
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false, currentAllMessage: data },
      });
      yield put({
        type: actions.UPDATE_IN_ALL_MESSAGE_LIST,
        payload: { data },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* GET_APPLICANT_ITEM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: { applicantLoading: true },
  });
  try {
    const {
      data,
      success,
      status,
      message: resMessage,
    } = yield call(Applicant.getApplicant, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { currentApplicantDetails: data, applicantLoading: false },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.SET_STATE,
      payload: { applicantLoading: false },
    });
  }
}

function* SEARCH_APPLICANTS({ payload }) {
  try {
    yield put({
      type: actions.SET_STATE,
      payload: { applicantSearchListLoading: true },
    });
    const {
      data,
      success,
      status,
      messaged: resMessage,
    } = yield call(Applicant.listApplicant, payload);

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          applicantSearchList: data.results,
        },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { applicantSearchListLoading: false },
    });
  }
}

function* UNREAD_FETCH({ payload }) {
  try {
    const { data, success, status } = yield call(
      Messages.getUnreadCount,
      payload
    );

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          unread: data.count,
        },
      });
    } else {
      yield handleApiError(status);
    }
  } catch (e) {
    console.error(e);
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_LIST_MESSAGES, LIST_MESSAGES),
    takeEvery(actions.INVOKE_CREATE_MESSAGE, CREATE_MESSAGE),
    takeEvery(actions.INVOKE_SEND_BULK_MESSAGE, SEND_BULK_MESSAGE),
    takeEvery(actions.INVOKE_LIST_ALL_MESSAGES, LIST_ALL_MESSAGES),
    takeEvery(actions.INVOKE_GET_MESSAGE, GET_MESSAGE),
    takeEvery(actions.INVOKE_GET_ALL_MESSAGE, GET_ALL_MESSAGE),
    takeEvery(actions.INVOKE_GET_APPLICANT_ITEM, GET_APPLICANT_ITEM),
    takeEvery(actions.INVOKE_SEARCH_APPLICANTS, SEARCH_APPLICANTS),
    takeEvery(actions.INVOKE_GET_MESSAGE_COUNT, UNREAD_FETCH),
  ]);
}
