import React, { useEffect } from 'react';
import { Button, Dropdown, Layout, Typography } from 'antd';
import { routes } from 'helpers/routes';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from 'redux/user/actions';
import messageActions from 'redux/messages/actions';
import Avatar from 'components/Avatar';
import getMediaUrl from 'helpers/getMediaUrl';
import store from 'store';
import { history } from 'helpers/history';
import SearchBar from 'components/SearchBar';

const { Header } = Layout;
const { Title } = Typography;

const MainHeader = ({
  fetchMyOrganization,
  loadCurrentUser,
  logout,
  organization,
  user,
  resetAllMessages,
  unread,
  fetchUnread,
}) => {
  const items = [
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.ACCOUNT);
          }}
        >
          Account settings
        </button>
      ),
      key: 'account-settings',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            resetAllMessages();
            history.push(routes.COMMUNICATIONS);
          }}
        >
          Communications {unread > 0 && <span>({unread})</span>}
        </button>
      ),
      key: 'communications',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.ALL_INTERVIEWS);
          }}
        >
          Interviews
        </button>
      ),
      key: 'all-interviews',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.ALL_APPLICANTS);
          }}
        >
          Candidates
        </button>
      ),
      key: 'all-applicants',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.ALL_RECORDINGS);
          }}
        >
          Recordings
        </button>
      ),
      key: 'all-recordings',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            logout();
          }}
        >
          Log Out
        </button>
      ),
      key: 'log-out',
    },
  ];

  useEffect(() => {
    const isLoggedIn = store.get('token');

    if (isLoggedIn && (!organization?.slug || !user?.email)) {
      fetchMyOrganization();
      loadCurrentUser();
    }
  }, [organization?.slug, user?.email]);

  useEffect(() => {
    fetchUnread();
  }, []);

  return (
    <Header className="d-flex justify-content-between align-items-center px-3">
      <Link to={routes.DASHBOARD}>
        <Title
          level={5}
          style={{ margin: '0' }}
          className="d-flex justify-content-center align-items-center gap-2"
        >
          <Avatar
            name={organization?.name}
            avatarUrl={getMediaUrl(organization?.logo?.filename)}
          />
          {organization?.name}
        </Title>
      </Link>
      <SearchBar />

      <div className="d-flex justify-content-center align-items-center">
        {/* NOTIFICATION UI Hidden */}
        {/* <Button
          key="1"
          type="text"
          onClick={() => {
            history.push(routes.NOTIFICATION);
          }}
        >
          <i className="bi bi-bell" />
        </Button> */}
        <Dropdown menu={{ items }} trigger={['click']}>
          <Button
            type="text"
            className="bg-transparent border-0 p-0 d-flex justify-content-center align-items-center gap-1"
          >
            <div className="position-relative">
              <Avatar
                name={user?.name}
                avatarUrl={getMediaUrl(user?.avatar?.filename)}
              />
              {unread > 1 && (
                <span
                  className="position-absolute top-0 end-0 badge rounded-pill bg-secondary"
                  style={{
                    fontSize: '0.75rem',
                    width: '1.2rem',
                    height: '1.2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: 'translate(50%, -50%)',
                  }}
                >
                  {unread}
                </span>
              )}
            </div>
            <i className="bi bi-caret-down" />
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ user, messages }) => {
  return {
    organization: user.organization,
    user: user.userData,
    unread: messages.unread,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () =>
      dispatch({
        type: actions.LOGOUT,
      }),
    fetchMyOrganization: () =>
      dispatch({
        type: actions.FETCH_MY_ORGANIZATION,
      }),
    resetAllMessages: () =>
      dispatch({
        type: messageActions.RESET_ALL_MESSAGES,
      }),

    loadCurrentUser: () => dispatch({ type: actions.LOAD_CURRENT_ACCOUNT }),
    fetchUnread: () =>
      dispatch({
        type: messageActions.INVOKE_GET_MESSAGE_COUNT,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
